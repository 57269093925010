* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1140px;
  margin: auto;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 2;

  &__title {
    text-align: center;
    font-size: 32px;
    padding: 16px 0;
  }

  &__nav {
    padding: 20px 0;

    &--list {
      display: flex;
      flex-direction: row;
      list-style: none;
      justify-content: space-between;

      &-item {
        transition: 250ms;
        border-bottom: 1px solid transparent;
        display: flex;
        &:hover {
          border-bottom: 1px solid black;
        }
      }

      &-link {
        color: black;
        text-decoration: none;
        padding: 10px 15px;
      }
    }
  }
}

.section {
  min-height: 100vh;
}

.main {
  background: linear-gradient(to right, #ffffff, rgba(172, 215, 255, 0.5));
}

.message {
  padding-top: 50vh;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 90vh;
    object-fit: contain;
    pointer-events: none;
  }

  &__title {
    font-size: 38px;

    & > span {
      display: block;
      font-size: 24px;
    }
  }

  &__contact {
    padding-top: 10vh;
    &--title {
      font-size: 28px;
    }

    &--form {
      &-input {
        outline: none;
        border: none;
        border-radius: 24px;
        padding: 16px 24px;
        box-shadow: 5px 5px 10px rgba(182, 182, 182, 0.3);
        margin-right: 32px;
        font-size: 24px;
      }

      &-btn {
        margin-top: 40px;
        z-index: 2;
        border-radius: 24px;
        padding: 16px 24px;
        border: none;
        background-color: rgb(46, 150, 247);
        font-size: 24px;
        color: white;
        cursor: pointer;

        transition: 250ms;

        &:hover {
          box-shadow: 5px 5px 10px rgba(182, 182, 182, 0.6);
        }

        &:disabled {
          opacity: 0.5;
          &:hover {
            box-shadow: none;
            cursor: auto;
          }
        }
      }
    }
  }
}

.about {
  padding-top: 140px;
  padding-bottom: 60px;

  &__title {
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 32px;
  }

  &__content {
    font-size: 24px;
    text-align: center;
    padding-top: 16px;
    width: 80%;
    margin: 0 auto;
  }

  &__full {
    font-size: 24px;
    padding-top: 48px;
    margin: 0 16px;
  }

  &__list {
    margin-left: 40px;
    margin-right: 16px;
    &--item {
      font-size: 24px;
      padding-top: 16px;
    }
  }
}

.howitworks {
  display: flex;
  align-items: center;

  &__title {
    font-size: 32px;
    text-align: center;
  }

  &__content {
    padding: 60px 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 60px;

    &--item {
      &-title {
        font-size: 24px;
        font-weight: 800;
        padding-bottom: 16px;
      }
      &-text {
        font-size: 18px;
      }
    }
  }
}

.services {
  padding-top: 140px;

  &__title {
    font-size: 32px;
    text-align: center;
    padding-bottom: 60px;
  }

  &__item {
    background-color: #acd7ff;
    margin-bottom: 16px;
    padding: 16px;
    height: auto;

    &--title {
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      border-bottom: 1px solid black;
      padding: 16px;
      &.closed {
        border-bottom: 1px solid transparent;
      }
    }

    &--sub {
      cursor: pointer;
      margin: 16px;
      max-height: var(--clientHeight);
      transition: all 200ms linear;
      & > div,
      ul {
        opacity: 1;
        visibility: visible;
        transition: all 200ms linear;
      }

      transition: all 200ms linear;
      &-title {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: bold;
      }

      &.closed {
        max-height: 0;
        margin: 0;
        & > div,
        ul {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &--optionals {
      max-height: var(--clientHeight);
      list-style-type: "- ";
      margin-left: 16px;
      transition: all 200ms linear;
      pointer-events: none;

      &-item {
        padding: 8px 0;
      }

      &-item--withdesc {
        display: flex;
        flex-direction: row;
        &-desc {
          margin-left: 32px;
        }

        & > span {
          min-width: 30%;
        }
      }

      & > li {
        opacity: 1;
        visibility: visible;
        transition: all 200ms linear;
        font-size: 20px;
      }
      &.closed-optional &-item {
        padding: 0 0;
      }
      &.closed-optional {
        max-height: 0;

        & > li {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.tooltip {
  opacity: 0;
  z-index: 99;
  color: #bbb;
  width: 190px;
  display: block;
  font-size: 11px;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  text-shadow: 1px 1px 2px #111;
  background: rgba(51, 51, 51, 0.9);
  border: 1px solid rgba(34, 34, 34, 0.9);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  transform: scale(0) translateX(50%);
  position: absolute;
  right: 50%;
  bottom: 40px;

  &::before,
  &::after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(51, 51, 51, 0.9);
    position: absolute;
    bottom: -10px;
    left: 43%;
  }
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: 32px;
    margin-bottom: 16px;
    text-align: center;
  }
  &__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--phonenumber {
      font-size: 24px;
      padding: 8px 0;
      cursor: pointer;
      color: rgb(85, 170, 250);
    }

    &--email {
      color: rgb(46, 150, 247);
      padding: 8px 0;
      font-size: 24px;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      display: inline-block;

      .tooltip.active {
        opacity: 1;
        transform: scale(1) translateX(50%);
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .message {
    &__image {
      z-index: -1;
    }
  }
}
